import { IFilter } from "src/@redux/listRedux";
import commonDataAccess from "../commonDataAccess";

class QuoteDataAccess {

  quoteList = async (params: {
    pageNo;
    pageSize;
    sortData;
    filters: Array<IFilter>;
  }) => {
    let _filters = {};
    console.log(params.filters);
    params.filters.forEach((item) => {
      if (item.value && item.value.length > 0) {
        _filters["filter." + item.propertyName] = item.value + "-" + item.type;
      }
    });
    let parameters = {
      pageNo: params.pageNo,
      pageSize: params.pageSize,
      sortPreference: params.sortData,
      ..._filters,
    };
    return await commonDataAccess.getData(
      "salesOrder/getAllquotes",
      parameters
    );
  };

  salesOrderList = async (params: {
    pageNo;
    pageSize;
    sortData;
    filters: Array<IFilter>;
  }) => {
    let _filters = {};
    console.log(params.filters);
    params.filters.forEach((item) => {
      if (item.value && item.value.length > 0) {
        _filters["filter." + item.propertyName] = item.value + "-" + item.type;
      }
    });
    let parameters = {
      pageNo: params.pageNo,
      pageSize: params.pageSize,
      sortPreference: params.sortData,
      ..._filters,
    };
    return await commonDataAccess.getData(
      "salesOrder/getAllsalesOrders",
      parameters
    );
  };

  getQuoteInfo = async (id: number | bigint, companyId: number | bigint | any) => {
    var result = await commonDataAccess.getData(
      "salesOrder/get/quoteInfo",
      {
        id: id,
        companyId: companyId
      }
    );
    return result;
  }
  saveQuoteInfo = (model: any) => {
    return commonDataAccess.postData("salesOrder/save/quoteInfo", model);
  };

  getSalesOrderInfo = async (id: number | bigint, companyId: number | bigint | any) => {
    var result = await commonDataAccess.getData(
      "salesOrder/get/salesOrderInfo",
      {
        id: id,
        companyId: companyId
      }
    );
    return result;
  }
  saveSalesOrderInfo = (model: any) => {
    return commonDataAccess.postData("salesOrder/save/salesOrderInfo", model);
  };
  saveQuoteOrderDetail = (model: any) => {
    return commonDataAccess.postData("salesOrder/save/quoteOrderDetail", model);
  };
  saveSalesOrderDetail = (model: any) => {
    return commonDataAccess.postData("salesOrder/save/salesOrderDetail", model);
  };
  getQuoteOrderDetail = async (salesOrderId: number | bigint | any) => {
    var result = await commonDataAccess.getData(
      "salesOrder/get/quoteOrderDetail",
      {
        salesOrderId: salesOrderId
      }
    );
    return result;
  };

  getSalesOrderDetail = async (salesOrderId: number | bigint | any) => {
    var result = await commonDataAccess.getData(
      "salesOrder/get/salesOrderDetail",
      {
        salesOrderId: salesOrderId
      }
    );
    return result;
  };

  deleteQuote = async (params: { ids: string }) => {
    var result = await commonDataAccess.deleteData("salesOrder/delete/" + params);
    return result;
  };

  deleteSalesOrder = async (params: { ids: string }) => {
    var result = await commonDataAccess.deleteData("salesOrder/delete/" + params);
    return result;
  };

  deleteSalesOrderDetail = async (params: { id: number | bigint | any }) => {
    return commonDataAccess.deleteData(
      "salesOrder/delete/quoteOrderDetail/" + params
    );
  };

  importEdiFile = (payload: any) => {
    return commonDataAccess.postData("salesOrder/import/ediFile", payload);
  };

  getAllCarriers = () => {
    return commonDataAccess.postData("salesOrder/get/GetAll3plCarriers");
  };

  GetMatching3plCarriers = async (carrierName: string | any, carrierServiceName: string | any) => {
    return await commonDataAccess.getData("salesOrder/get/GetMatching3plCarriers",
      {
        carrierName: carrierName,
        carrierServiceName: carrierServiceName
      }
    );
  };

  GetSalesByCustomers = async (startDate: Date | any, endDate: Date | any, companyId: bigint | any) => {
    return await commonDataAccess.getData("salesOrder/get/GetSalesByCustomers",
      {
        startDate: startDate,
        endDate: endDate,
        companyId: companyId
      }
    );
  };

  GetSalesByProducts = async (startDate: Date | any, endDate: Date | any, productId: bigint | any) => {
    return await commonDataAccess.getData("salesOrder/get/GetSalesByProducts",
      {
        startDate: startDate,
        endDate: endDate,
        productId: productId
      }
    );
  };

  printSalesByCustomersReport = (
    startDate: any,
    endDate: any,
    reportType: any,
    downloadProgress: (progress: any) => void
  ) => {
    return commonDataAccess.downloadPost({
      url: "salesOrder/get/salesByCustomersReport",
      payload: null,
      params: {
        startDate: startDate,
        endDate: endDate,
        reportType: reportType
      },
      onDowloadProgress: downloadProgress
    });
  };

  printSalesByProductsReport = (
    startDate: any,
    endDate: any,
    reportType: any,
    downloadProgress: (progress: any) => void
  ) => {
    return commonDataAccess.downloadPost({
      url: "salesOrder/get/salesByProductsReport",
      payload: null,
      params: {
        startDate: startDate,
        endDate: endDate,
        reportType: reportType
      },
      onDowloadProgress: downloadProgress
    });
  };

  GetHistoricStockValuations = async () => {
    return await commonDataAccess.getData("salesOrder/get/GetHistoricStockValuations",
      {}
    );
  };

  printHistoricStockValuations = (
    startDate: any,
    endDate: any,
    reportType: any,
    downloadProgress: (progress: any) => void
  ) => {
    return commonDataAccess.downloadPost({
      url: "salesOrder/get/historicStockValuationsReport",
      payload: null,
      params: {
        startDate: startDate,
        endDate: endDate,
        reportType: reportType
      },
      onDowloadProgress: downloadProgress
    });
  };

  onDeliveryOrderConfirmation = async (model) => {
    return await commonDataAccess.postData("salesOrder/deliveryOrderConfirmationEmail", model);
  };

  getOrderConfirmationEmailTemplate = async (salesOrderId: any) => {
    return await commonDataAccess.getData("salesOrder/getOrderConfirmationEmailTemplate", { id: salesOrderId });
  };


  printDeliveryOrderConfirmationReport = (
    salesOrderId: any,
    downloadProgress: (progress: any) => void
  ) => {
    return commonDataAccess.downloadPost({
      url: "salesOrder/printDeliveryOrderConfirmationReport",
      payload: null,
      params: {
        id: salesOrderId
      },
      onDowloadProgress: downloadProgress
    });
  };

  printSalesOrderInvoiceReport = (
    salesOrderId: any,
    downloadProgress: (progress: any) => void
  ) => {
    return commonDataAccess.downloadPost({
      url: "salesOrder/printSalesOrderInvoiceReport",
      payload: null,
      params: {
        id: salesOrderId
      },
      onDowloadProgress: downloadProgress
    });
  };

  deletePayment = async (params: { id: number | bigint | any }) => {
    return commonDataAccess.deleteData(
      "salesOrder/delete/deletePayment/" + params
    );
  };

  saveSalesOrderPaymentDetail = async (paymentInfo: number | bigint | any) => {
    var result = await commonDataAccess.postData(
      "salesOrder/save/salesOrderPaymentDetail",
      paymentInfo
    );
    return result;
  };

  getSalesOrderPaymentDetail = async (salesOrderId: number | bigint | any) => {
    var result = await commonDataAccess.getData(
      "salesOrder/get/salesOrderPaymentDetail",
      {
        salesOrderId: salesOrderId
      }
    );
    return result;
  };

  getPaymentTypesForDropdown = () => {
    return commonDataAccess.getData("salesOrder/get/paymentTypesForDropdown");
  };

  exportSalesByCustomerDataToExcel(startDate: any,
    endDate: any) {
    return commonDataAccess.downloadFile("salesOrder/export/salesByCustomerDataToExcel",
      {
        startDate: startDate,
        endDate: endDate
      }
    );
  }
  exportHistoricStockValuationsDataToExcel() {
    return commonDataAccess.downloadFile("salesOrder/export/historicStockValuationsDataToExcel"
    );
  }

  exportSalesByProductDataToExcel(startDate: any,
    endDate: any) {
    return commonDataAccess.downloadFile("salesOrder/export/salesByProductDataToExcel",
      {
        startDate: startDate,
        endDate: endDate
      }
    );
  }


}
export default new QuoteDataAccess();
